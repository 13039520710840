<template>
  <b-row>
    <b-col sm="12" md="12" class="mt-1">
      <b-form @submit.prevent>
        <b-card>
          <b-card-body>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.name") }}</label>
                    <inputvselect
                      name="holdername"
                      :label="(reldata) => reldata.name"
                      :valuex="appData.holdernameid"
                      keys="key"
                      @updatedata="(val) => updatedetails('holdernameid', val)"
                      :options="familymembers"
                      id="holdername"
                      validations="required"
                    ></inputvselect>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.propertytype") }}</label>
                    <inputselect
                      name="propertytype"
                      :tooltip="
                        $t('fields.select') + ' ' + $t('fields.propertytype')
                      "
                      :placeholder="
                        $t('fields.select') + ' ' + $t('fields.propertytype')
                      "
                      :valuex="appData.propertytype"
                      keys="key"
                      @updatedata="(val) => managedata('propertytype', val)"
                      :options="propertytype"
                      id="propertytype"
                      validations="required"
                    ></inputselect>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.propertytype != 'Rented'"
                  >
                    <label> {{ $t("fields.ownedby") }}</label>
                    <inputselect
                      name="ownedby"
                      :tooltip="
                        $t('fields.select') + ' ' + $t('fields.ownedby')
                      "
                      :placeholder="
                        $t('fields.select') + ' ' + $t('fields.ownedby')
                      "
                      :valuex="appData.ownedby"
                      keys="key"
                      @updatedata="(val) => (appData.ownedby = val)"
                      :options="['Solely', 'Jointly']"
                      id="ownedby"
                      validations=""
                    ></inputselect>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      ['Jointly'].includes(appData.ownedby) &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <inputtext
                      name="ownership"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.ownership')
                      "
                      :label="$t('fields.ownership')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.ownership')
                      "
                      :valuex="appData.ownership"
                      @updatedata="(val) => (appData.ownership = val)"
                    ></inputtext>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.propertytype != 'Rented'"
                  >
                    <!-- v-if="!['Rented','Residing'].includes(appData.propertytype)" -->

                    <inputtext
                      name="registrationnumber"
                      :tooltip="
                        $t('fields.enter') +
                        ' ' +
                        $t('fields.registrationnumber')
                      "
                      :label="$t('fields.registrationnumber')"
                      :placeholder="
                        $t('fields.enter') +
                        ' ' +
                        $t('fields.registrationnumber')
                      "
                      :valuex="appData.regnno"
                      @updatedata="(val) => (appData.regnno = val)"
                      validations="required|alpha-num"
                    ></inputtext>
                  </b-col>

                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="address"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.address')"
                      :label="$t('fields.address')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.address')
                      "
                      :valuex="appData.address"
                      @updatedata="(val) => (appData.address = val)"
                      validations="required"
                    >
                    </inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.state") }}</label>
                    <inputselect
                      name="state"
                      :tooltip="$t('fields.select') + ' ' + $t('fields.state')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.state')
                      "
                      :valuex="appData.state"
                      keys="key"
                      @updatedata="(val) => (appData.state = val)"
                      :options="state"
                      id="state"
                      validations=""
                    ></inputselect>
                  </b-col>

                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="city"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.city')"
                      :label="$t('fields.city')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.city')
                      "
                      :valuex="appData.city"
                      @updatedata="(val) => (appData.city = val)"
                      validations=""
                    >
                    </inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="pincode"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.pincode')"
                      :label="$t('fields.pincode')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.pincode')
                      "
                      :valuex="appData.pincode"
                      @updatedata="(val) => (appData.pincode = val)"
                      validations="required|integer|length:6"
                    ></inputtext>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.propertytype != 'Rented'"
                  >
                    <inputtext
                      name="areainsequarefeet"
                      :tooltip="
                        $t('fields.enter') +
                        ' ' +
                        $t('fields.areainsequarefeet')
                      "
                      :label="$t('fields.areainsequarefeet')"
                      :placeholder="
                        $t('fields.enter') +
                        ' ' +
                        $t('fields.areainsequarefeet')
                      "
                      :valuex="appData.areainsequarefeet"
                      @updatedata="(val) => (appData.areainsequarefeet = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="propertyid"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.propertyid')
                      "
                      :label="$t('fields.propertyid')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.propertyid')
                      "
                      :valuex="appData.propertyid"
                      @updatedata="(val) => (appData.propertyid = val)"
                      validations="required"
                    >
                    </inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.propertytype != 'Rented'"
                  >
                    <label> {{ $t("fields.occupanttype") }}</label>
                    <inputselect
                      name="occupanttype"
                      :tooltip="
                        $t('fields.select') + ' ' + $t('fields.occupanttype')
                      "
                      :placeholder="
                        $t('fields.select') + ' ' + $t('fields.occupanttype')
                      "
                      :valuex="appData.status"
                      keys="key"
                      @updatedata="(val) => (appData.status = val)"
                      :options="[
                        'Residing',
                        'Let Out',
                        'Vacant',
                        'Vacation Home',
                      ]"
                      id="status"
                      validations="required"
                    ></inputselect>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.status == 'Let Out'"
                  >
                    <inputtext
                      name="tenantname"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.tenantname')
                      "
                      :label="$t('fields.tenantname')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.tenantname')
                      "
                      :valuex="appData.tenantname"
                      @updatedata="(val) => (appData.tenantname = val)"
                      validations=""
                    >
                    </inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.status == 'Let Out'"
                  >
                    <inputtext
                      name="advancereceived"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.advancereceived')
                      "
                      :label="$t('fields.advancereceived')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.advancereceived')
                      "
                      :valuex="appData.advancereceived"
                      @updatedata="
                        (val) => managefloat('advancereceived', parseFloat(val))
                      "
                      validations="required"
                    ></inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.propertytype == 'Rented'"
                  >
                    <inputtext
                      name="advancepaid"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.advancepaid')
                      "
                      :label="$t('fields.advancepaid')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.advancepaid')
                      "
                      :valuex="appData.advancepaid"
                      @updatedata="
                        (val) => managefloat('advancepaid', parseFloat(val))
                      "
                      validations="required|amount"
                    ></inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      appData.propertytype != 'Rented' &&
                      [
                        'Residing',
                        'Let Out',
                        'Vacant',
                        'Vacation Home',
                      ].includes(appData.status)
                    "
                  >
                    <inputtext
                      name="propertytax"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.propertytax')
                      "
                      :label="$t('fields.propertytax')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.propertytax')
                      "
                      :valuex="appData.propertytax"
                      @updatedata="(val) => (appData.propertytax = val)"
                      validations=""
                    ></inputtext>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    class=""
                    v-if="
                      appData.propertytype != 'Rented' &&
                      [
                        'Residing',
                        'Let Out',
                        'Vacant',
                        'Vacation Home',
                      ].includes(appData.status)
                    "
                  ><label> {{ $t("fields.financeyear") }}</label>
                    <inputvselect
                      name="financeyearname"
                      :label="(reldata) => reldata.id"
                      :valuex="appData.financeyear"
                      keys="key"
                      @updatedata="
                        (val) => updatedetailsnom('financeyear', val)
                      "
                      :options="yearlist"
                      id="financeyearname"
                      validations=""
                    ></inputvselect>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class=""
                    v-if="
                      appData.propertytype != 'Rented' &&
                      [
                        'Residing',
                        'Let Out',
                        'Vacant',
                        'Vacation Home',
                      ].includes(appData.status)
                    "
                  >
                    <inputtext
                      name="marketvalue"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.marketvalue')
                      "
                      :label="$t('fields.marketvalue')"
                      :valuex="appData.marketvalue"
                      @updatedata="
                        (val) => managefloat('marketvalue', parseFloat(val))
                      "
                      validations="amount"
                    ></inputtext>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.propertytype == 'Rented'"
                  >
                    <label> {{ $t("fields.agreementstartdate") }}</label>
                    <flat-pickr
                      v-model="appData.startdate"
                      :config="{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                        maxDate: today,
                      }"
                      locale="en-In"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    />
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.propertytype == 'Rented'"
                  >
                    <label> {{ $t("fields.agreementenddate") }}</label>
                    <flat-pickr
                      v-model="appData.enddate"
                      :config="{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                        minDate: today,
                      }"
                      locale="en-In"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    />
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.propertytype == 'Rented'"
                  >
                    <inputtext
                      name="rent"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.rent')"
                      :label="$t('fields.rent')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.rent')
                      "
                      :valuex="appData.rent"
                      @updatedata="
                        (val) => managefloat('rent', parseFloat(val))
                      "
                      validations="required"
                    ></inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.status == 'Let Out'"
                  >
                    <label> {{ $t("fields.agreementstartdate") }}</label>
                    <flat-pickr
                      v-model="appData.startdate"
                      :config="{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                        maxDate: today,
                      }"
                      locale="en-In"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    />
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.status == 'Let Out'"
                  >
                    <label> {{ $t("fields.agreementenddate") }}</label>
                    <flat-pickr
                      v-model="appData.enddate"
                      :config="{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                        minDate: today,
                      }"
                      locale="en-In"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    />
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="appData.status == 'Let Out'"
                  >
                    <inputtext
                      name="rentamount"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.rentamount')
                      "
                      :label="$t('fields.rentamount')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.rentamount')
                      "
                      :valuex="appData.rentamount"
                      @updatedata="
                        (val) => managefloat('rentamount', parseFloat(val))
                      "
                      validations="amount"
                    ></inputtext>
                  </b-col>

                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="remarks"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.remarks')"
                      :label="$t('fields.remarks')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.remarks')
                      "
                      :valuex="appData.remarks"
                      @updatedata="(val) => (appData.remarks = val)"
                      validations=""
                    >
                    </inputtext>
                  </b-col>
                  <!-- ////////////LoAN//////////// -->
                  <b-col
                    cols="12"
                    v-if="!edittype && appData.propertytype != 'Rented'"
                  >
                    <hr />
                    <b-form-checkbox
                      checked="true"
                      class="mt-2"
                      name="check-button"
                      switch
                      v-model="appData.loandata"
                      inline
                    >
                      <h5 class="text-primary">{{ $t("fields.mortgaged") }}</h5>
                    </b-form-checkbox>
                    <h4 class="fw-bolder mt-1" v-if="appData.loandata">
                      {{ $t("navigation.loan") }}
                    </h4>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <label> {{ $t("fields.holdername") }}</label>
                    <br />
                    <b> {{ appData.holdername }} </b>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    v-if="
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <label class="mt-1"> {{ $t("fields.propertyid") }}</label>
                    <br />
                    <b>{{ appData.propertyid }} </b>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      ['Auto Loan'].includes(appData.category) &&
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <label class="mt-1">
                      {{ $t("fields.registrationnumber") }}</label
                    >
                    <br />
                    <b>{{ appData.regnno }}</b>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <label> {{ $t("fields.typeofloan") }}</label>
                    <inputselect
                      name="typeofloan"
                      :tooltip="
                        $t('fields.select') + ' ' + $t('fields.typeofloan')
                      "
                      :placeholder="
                        $t('fields.select') + ' ' + $t('fields.typeofloan')
                      "
                      :valuex="appData.category"
                      keys="key"
                      @updatedata="(val) => (appData.category = val)"
                      :options="category"
                      id="category"
                      validations="required"
                    ></inputselect>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <inputtext
                      name="lender"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.lender')"
                      :label="$t('fields.lender')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.lender')
                      "
                      :valuex="appData.loanavailedfrom"
                      @updatedata="(val) => (appData.loanavailedfrom = val)"
                      validations="required"
                    ></inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <inputtext
                      name="loanid"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.loanid')"
                      :label="$t('fields.loanid')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.loanid')
                      "
                      :valuex="appData.loanid"
                      @updatedata="(val) => (appData.loanid = val)"
                      validations=""
                    >
                    </inputtext>
                  </b-col>
                  <b-col
                  sm="12" md="6" class="mt-1"
                    v-if="
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <inputtext
                      name="tenure"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.tenure')"
                      :label="$t('fields.tenure')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.tenure')
                      "
                      :valuex="appData.tenure"
                      @updatedata="
                        (val) => {
                          appData.tenure = val;
                          emicalculate();
                        }
                      "
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <inputtext
                      name="sanctionedamount"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.sanctionedamount')
                      "
                      :label="$t('fields.sanctionedamount')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.sanctionedamount')
                      "
                      :valuex="appData.sanctionedamount"
                      @updatedata="
                        (val) => {
                          appData.sanctionedamount = val;
                          emicalculate();
                        }
                      "
                      validations="required|integer"
                    ></inputtext>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <inputtext
                      name="rateofintrest"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.rateofintrest')
                      "
                      :label="$t('fields.rateofintrest')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.rateofintrest')
                      "
                      :valuex="appData.rateofintrest"
                      @updatedata="
                        (val) => {
                          appData.rateofintrest = val;
                          emicalculate();
                        }
                      "
                      validations="minvalue:0.1|maxvalue:100"
                    >
                    </inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <label> {{ $t("fields.startdate") }}</label>
                    <flat-pickr
                      v-model="appData.startdate"
                      :config="{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                        maxDate: today,
                        onChange:emicalculate()
                      }"
                      locale="en-In"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    />
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                  <inputtext
                      name="emi"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.emi')"
                      :label="$t('fields.emi')"
                      :disabled=1
                      :placeholder="$t('fields.enter') + ' ' + $t('fields.emi')"
                      :valuex="appData.emi || 0"
                      @updatedata="(val) => (appData.emi = val)"
                      validations="required|amount"
                    ></inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <b-row>
                      <b-col class="col-5">
                        <inputtext
                          name="date"
                          :label="$t('fields.emiduedate')"
                          :tooltip="
                            $t('fields.enter') + ' ' + $t('fields.pleaseinput')
                          "
                          placeholder="DD"
                          :valuex="appData.emidate"
                          @updatedata="(val) => (appData.emidate = val)"
                          validations="required|min:1|maxvalue:31"
                        ></inputtext>
                      </b-col>
                      <b-col class="col-7 mt-1">
                        <label class="pt-1">
                          {{ $t("fields.ofeverymonth") }}</label
                        >
                      </b-col>
                    </b-row>
                  </b-col>
                  <!-- <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <label> {{ $t("fields.emideddate") }}</label>
                    <flat-pickr
                      v-model="appData.emideddate"
                      :config="{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                      }"
                      locale="en-In"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    />
                  </b-col> -->
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <label> {{ $t("fields.dateofclosure") }}</label>
                    <flat-pickr
                      v-model="appData.dateofclosure"
                      :config="{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                        minDate: today,
                      }"
                      locale="en-In"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    />
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <inputtext
                      name="prinipalamount"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.prinipalamount')
                      "
                      :label="$t('fields.prinipalamount')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.prinipalamount')
                      "
                      :valuex="appData.prinipalamount"
                      @updatedata="
                        (val) => managefloat('prinipalamount', parseFloat(val))
                      "
                      validations="required|integer|amount"
                    ></inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.loandata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <label> {{ $t("fields.dateofentry") }}</label>
                    <p>{{ appData.date }}</p>
                    <!-- <flat-pickr
                      v-model="appData.dateofentry"
                      :config="{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                        maxDate: today,
                      }"
                      locale="en-In"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    /> -->
                  </b-col>
                  <!-- /////////////INSURANCE/////////// -->
                  <b-col
                    cols="12"
                    v-if="!edittype && appData.propertytype != 'Rented'"
                  >
                    <hr />
                    <b-form-checkbox
                      checked="true"
                      name="check-button"
                      class="mt-1"
                      switch
                      v-model="appData.insurancedata"
                      inline
                    >
                      <h5 class="text-primary">
                        {{ $t("fields.isitinsured") }}
                      </h5>
                    </b-form-checkbox>
                    <h4 class="fw-bolder mt-3" v-if="appData.insurancedata">
                      {{ $t("fields.insurance") }}
                    </h4>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.insurancedata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <label> {{ $t("fields.ownername") }}</label>
                    <br />
                    <b>{{ appData.holdername }}</b>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    v-if="
                      !edittype &&
                      appData.insurancedata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <label class="mt-1"> {{ $t("fields.propertyid") }}</label>
                    <br />
                    <b>{{ appData.propertyid }} </b>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.insurancedata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <label> {{ $t("fields.insurancecompany") }}</label>
                    <inputselect
                      name="insurancecompany"
                      :tooltip="
                        $t('fields.select') +
                        ' ' +
                        $t('fields.insurancecompany')
                      "
                      :placeholder="
                        $t('fields.select') +
                        ' ' +
                        $t('fields.insurancecompany')
                      "
                      :valuex="appData.insurancecompany"
                      keys="key"
                      @updatedata="(val) => (appData.insurancecompany = val)"
                      :options="insurancecom"
                      id="insurancecompany"
                      validations=""
                    ></inputselect>
                  </b-col>

                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.insurancedata &&
                      appData.insurancecompany === 'Others' &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <inputtext
                      name="custominsurance"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.custominsurance')
                      "
                      :valuex="appData.custominsurance"
                      keys="key"
                      @updatedata="(val) => (appData.custominsurance = val)"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.custominsurance')
                      "
                      :label="$t('fields.custominsurance')"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.insurancedata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <inputtext
                      name="policynumber"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.policynumber')
                      "
                      :label="$t('fields.policynumber')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.policynumber')
                      "
                      :valuex="appData.policynumber"
                      @updatedata="(val) => (appData.policynumber = val)"
                      validations="required|alpha-num"
                    ></inputtext>
                  </b-col>
                  <!-- <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                     v-if="!edittype &&
                       appData.insurancedata &&
                       appData.propertytype != 'Rented'
                       "
                  >
                    <label class="mt-1">
                      {{ $t("fields.registrationnumber") }}</label
                    >
                    <br />
                    <b>{{ appData.regnno }} </b>
                  </b-col> -->
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.insurancedata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <inputtext
                      name="sumassured"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.sumassured')
                      "
                      :label="$t('fields.sumassured')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.sumassured')
                      "
                      :valuex="appData.sumassured"
                      @updatedata="
                        (val) => managefloat('sumassured', parseFloat(val))
                      "
                      validations="amount|required"

                    ></inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.insurancedata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <inputtext
                      name="premiumamount"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.premiumamount')
                      "
                      :label="$t('fields.premiumamount')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.premiumamount')
                      "
                      :valuex="appData.premiumamount"
                      @updatedata="
                        (val) => managefloat('premiumamount', parseFloat(val))
                      "
                      :validations="'amount|maxvalue:' + appData.sumassured"
                    ></inputtext>
                  </b-col>


                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.insurancedata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <label> {{ $t("fields.renewaldate") }}</label>
                    <flat-pickr
                      v-model="appData.renewaldate"
                      :config="{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                        minDate: today,
                      }"
                      locale="en-In"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    />
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.insurancedata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <label> {{ $t("fields.nominee") }}</label>
                    <inputvselect
                      name="nomineename"
                      :label="(reldata) => reldata.data.name"
                      :valuex="appData.nomineenameid"
                      keys="key"
                      @updatedata="
                        (val) => updatedetailsnom('nomineenameid', val)
                      "
                      :options="nomineetetals"
                      id="nomineename"
                      validations=""
                    ></inputvselect>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      !edittype &&
                      appData.insurancedata &&
                      appData.propertytype != 'Rented'
                    "
                  >
                    <inputtext
                      name="remarks"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.remarks')"
                      :label="$t('fields.remarks')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.remarks')
                      "
                      :valuex="appData.remarks"
                      @updatedata="(val) => (appData.remarks = val)"
                      validations=""
                    >
                    </inputtext>
                  </b-col>
                  <!-- ///////////Insurance End////////////////// -->
                  <b-col sm="12" v-if="edittype == 0">
                    <h5 class="mt-2">{{ $t("fields.fileupload") }}</h5>
                    <b-row>
                      <b-col sm="12" md="6" class="mt-1" lg="6">
                        <validation-provider
                          #default="validationContext"
                          name="Upload File"
                          rules=""
                        >
                          <b-form-group
                            :label="$t('fields.uploadfile')"
                            label-for="fileinput"
                          >
                            <b-form-file
                              accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"
                              id="fileinput"
                              name="Upload File "
                              :placeholder="$t('fields.uploadfile')"
                              drop-placeholder="Drop file here..."
                              @change="updateValue($event)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col sm="12">
                    <div class="d-flex float-right">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        class="mr-1"
                        variant="secondary"
                        @click="$router.go(-1)"
                      >
                        <feather-icon icon="ArrowLeftIcon" class="mr-25" />
                        <span class="align-middle"
                          >{{ $t("fields.back") }}
                        </span>
                      </b-button>
                      <b-button
                        v-if="edittype == 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        :to="{
                          name: GENAPP_APP_STORE_MODULE_NAME + '-view',
                          params: { id: iddata },
                        }"
                      >
                        <feather-icon icon="EyeIcon" class="mr-25" />
                        {{ $t("fields.view") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary                            "
                        class="mr-1"
                        type="submit"
                      >
                        <feather-icon icon="Edit3Icon" class="mr-25" />
                        {{ $t("fields.save") }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
              <!--/ form -->
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inputvselect from "@/views/Component/Input/inputvselect.vue";
import inputselect from "@/views/Component/Input/inputselect.vue";
import inputtext from "@/views/Component/Input/inputtext.vue";
import inputtextarea from "@/views/Component/Input/inputtextarea.vue";
import { required, alphaNum, maxvalue } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  components: {
    BCol,
    BCard,
    BRow,
    BForm,
    flatPickr,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BFormGroup,
    inputselect,
    inputvselect,
    inputtextarea,
    inputtext,
    BFormCheckbox,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedYear: "",
      startYear: 1990,
      endYear: new Date().getFullYear(),
      required,
      alphaNum,
      maxvalue,
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "physicalassets-realestate";
    const modulename = "Real Estate";
    const modulenamesub = "realestate";
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    let today = new Date();
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    const state = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchestate")
      .then((response) => {
        state.value = response.data.data;
      });
    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    let iddata = router.currentRoute.params.id;
    if (iddata != undefined) {
      edittype = 1;
      storetype = "editData";
    }
    const blankAppData = {
      name: "",
      holdernameid: "",
      holdername: "",
      propertytype: "",
      propertyid: "",
      rentamount: 0,
      financeyear: "",
      startdate: "",
      areainsequarefeet: "",
      ownership: "",
      areaofplot: "",
      enddate: "",
      propertytax: "",
      registrationdate: "",
      advancepaid: 0,
      tenantname: "",
      marketvalue: 0,
      rent: 0,
      address: "",
      city: "",
      ownedby: "",
      insurancecompany: "",
      advancereceived: 0,
      category: "",
      regnno: "",
      state: "",
      pincode: "",
      remarks: "",
      status: "",
      insurancedata: "",
      loandata: 0,
      prinipalamount:0,
      date: date,
      yearlist: [],
      years: "",
      fileData: {
        name: "",
        file: "",
        size: "",
        type: "",
      },
    };
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));
    var tempcatch = 1;
    const onSubmit = () => {
      let temp = appData.value;
      let formData = new FormData();
      let appdatax = {
        customvalue: temp.relation != "Others" ? 0 : 1,
        customrelation: temp.customrelation || "",
        propertytype: temp.propertytype,
        ownedby: temp.ownedby,
        address: temp.address,
        rent: temp.rent,
        ownership: temp.ownership,
        rentamount: temp.rentamount,
        areaofplot: temp.areaofplot,
        propertytax: temp.propertytax,
        name: temp.address,
        tenantname: temp.tenantname,
        prinipalamount: temp.prinipalamount,
        status: temp.status,
        financeyear: temp.financeyear,
        areainsequarefeet: temp.areainsequarefeet,
        registrationdate: temp.registrationdate,
        marketvalue: temp.marketvalue,
        holdernameid: temp.holdernameid,
        holdername: temp.holdername,
        city: temp.city,
        advancereceived: temp.advancereceived,
        regnno: temp.regnno,
        state: temp.state,
        category: temp.category,
        propertyid: temp.propertyid,
        advancepaid: temp.advancepaid,
        remarks: temp.remarks,
        startdate: temp.startdate,
        enddate: temp.enddate,
        pincode: temp.pincode,
      };
      let metadataappdatax = {
        customvalue: temp.relation != "Others" ? 0 : 1,
        customrelation: temp.customrelation || "",
        holdernameid: temp.holdernameid,
        registrationdate: temp.registrationdate,
        advancepaid: temp.advancepaid,
        status: temp.status,
        holdername: temp.holdername,
        propertytype: temp.propertytype,
        rent: temp.rent,
        prinipalamount: temp.prinipalamount,
        category: temp.category,
        financeyear: temp.financeyear,
        enddate: temp.enddate
          ? temp.enddate.split("-").reverse().join("-")
          : null,
        startdate: temp.startdate
          ? temp.startdate.split("-").reverse().join("-")
          : null,
        regnno: temp.regnno,
        name: temp.address,
      };
      let loanappdatax = {
        customvalue: temp.relation != "Others" ? 0 : 1,
        customrelation: temp.customrelation || "",
        holdername: temp.holdername,
        name: temp.name,
        type: "Loan",
        holdernameid: temp.holdernameid,
        category: temp.category,
        loanavailedfrom: temp.loanavailedfrom,
        marketvalue: temp.marketvalue,
        loanid: temp.loanid,
        propertyid: temp.propertyid,
        prinipalamount: temp.prinipalamount,
        emi: temp.emi,
        tenure: temp.tenure,
        date: temp.date,
        startdate: temp.startdate,
        emideddate: temp.emideddate,
        rateofintrest: temp.rateofintrest,
        enddate: temp.enddate,
        dateofentry: temp.dateofentry,
        sanctionedamount: temp.sanctionedamount,
        emidate: temp.emidate,
        dateofclosure: temp.dateofclosure,
        remarks: temp.remarks,
      };
      let loanmetadatax = {
        customvalue: temp.relation != "Others" ? 0 : 1,
        customrelation: temp.customrelation || "",
        holdername: temp.holdername,
        name: temp.loanid,
        propertyid: temp.propertyid,
        holdernameid: temp.holdernameid,
        startdate: temp.startdate
          ? temp.startdate.split("-").reverse().join("-")
          : null,
        category: temp.category,
        dateofentry: temp.dateofentry,
        date: temp.date ? temp.date.split("-").reverse().join("-") : null,
        emideddate: temp.emideddate
          ? temp.emideddate.split("-").reverse().join("-")
          : null,
        emidate: temp.emidate
          ? temp.emidate.split("-").reverse().join("-")
          : null,
        dateofclosure: temp.dateofclosure
          ? temp.dateofclosure.split("-").reverse().join("-")
          : null,
        prinipalamount: temp.prinipalamount,
        sanctionedamount: temp.sanctionedamount,
        rateofintrest: temp.rateofintrest,
        type: "Loan",
        enddate: temp.enddate
          ? temp.enddate.split("-").reverse().join("-")
          : null,
      };
      let insuranceappdatax = {
        type: (temp.type = 0),
        customvalue: temp.insurancecompany != "Others" ? 0 : 1,
        insurancecompany: temp.insurancecompany,
        type: "Insurance",
        finalinsurancecom:
          temp.insurancecompany == "Others"
            ? temp.custominsurance
            : temp.insurancecompany,
        custominsurance: temp.custominsurance,
        holdername: temp.holdername,
        name: temp.regnno,
        renewaldate: temp.renewaldate,
        // regnno: temp.regnno,
        holdernameid: temp.holdernameid,
        sumassured: temp.sumassured,
        expirydate: temp.expirydate,
        propertyid: temp.propertyid,
        nomineename: temp.nomineename,
        nomineenameid: temp.nomineenameid,
        premiumamount: temp.premiumamount,
        policynumber: temp.policynumber,
        nominee: temp.nominee,
        remarks: temp.remarks,
      };
      let metainsuranceppdatax = {
        customvalue: temp.insurancecompany != "Others" ? 0 : 1,
        insurancecompany: temp.insurancecompany,
        type: "Insurance",
        finalinsurancecom:
          temp.insurancecompany == "Others"
            ? temp.custominsurance
            : temp.insurancecompany,
        custominsurance: temp.custominsurance,
        holdername: temp.holdername,
        // regnno: temp.regnno,
        name: temp.regnno,
        nomineename: temp.nomineename,
        type: temp.type,
        policynumber: temp.policynumber,
        sumassured: temp.sumassured,
        premiumamount: temp.premiumamount,
        nominee: temp.nominee,
        propertyid: temp.propertyid,
        expirydate: temp.expirydate
          ? temp.expirydate.split("-").reverse().join("-")
          : null,
        renewaldate: temp.renewaldate
          ? temp.renewaldate.split("-").reverse().join("-")
          : null,
        holdernameid: temp.holdernameid,
      };
      if (edittype == 1) {
        var jx = {
          id: router.currentRoute.params.id || null,
          customvalue: metadataappdatax.customvalue,
          data: appdatax,
          name: temp.propertyid,
          metadata: metadataappdatax,
        };
      } else {
        formData.append("name", temp.propertyid);
        formData.append("customvalue", metadataappdatax.customvalue);
        formData.append("data", JSON.stringify(appdatax));
        formData.append("metadata", JSON.stringify(metadataappdatax));
        if (temp.loandata) {
          formData.append("loandata", JSON.stringify(loanappdatax));
          formData.append("metaloandata", JSON.stringify(loanmetadatax));
        }
        if (temp.insurancedata) {
          formData.append("insurancedata", JSON.stringify(insuranceappdatax));
          formData.append(
            "metainsurancedata",
            JSON.stringify(metainsuranceppdatax)
          );
        }
        if (temp.fileData.file) {
          let file = temp.fileData.file;
          formData.append("file", file);
        }
      }
      if (tempcatch) {
        tempcatch = 0;
        store
          .dispatch(
            GENAPP_APP_STORE_MODULE_NAME + "/" + storetype,
            edittype == 0 ? formData : jx
          )
          .then((response) => {
            if (response.data.success) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  text:
                    modulename +
                    (edittype == 1 ? " editted" : " added") +
                    " successfully",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            } else if (response.data.success == 0) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  variant: "error",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            }
          })
          .catch((error) => {
            tempcatch = 1;
            if (error.response.status === 404) {
              appData.value = undefined;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text: "Couldnt find " + modulenamesub + " details",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };
    const familymembers = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchefamilymemberdetails")
      .then((response) => {
        familymembers.value = response.data.data;
      });
    const insurancecom = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetcheinsurancecom")
      .then((response) => {
        insurancecom.value = response.data.data;
        if (!insurancecom.value.includes("Others")) {
          insurancecom.value.push("Others");
        }
      });
    const nomineetetals = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchenomineedetails")
      .then((response) => {
        nomineetetals.value = response.data.data;
      });
    if (iddata != undefined) {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          if (!response.data.data) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            router
              .push({
                name: GENAPP_APP_STORE_MODULE_NAME,
              })
              .catch(() => {});
          }

          appData.value = response.data.data.data;
          appData.value.name = response.data.data.name;
          appData.value.status = response.data.data.status;

          key = Math.random();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value = undefined;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 500) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Input fields missing values",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 422) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Please refresh your page",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    }
    const yearlist = ref([]);
    var now = new Date();
    var datex = new Date();
    datex.setFullYear(datex.getFullYear() - 5);
    var years = datex.getFullYear();
    var yearsmax = now.getFullYear();
    for (var i = years; i <= yearsmax; i++) {
      var tempx = parseInt(i) + 1;
      var x = {
        id: i + "-" + tempx,
        from: i,
        to: tempx,
      };
      yearlist.value.push(x);
    }
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };
    const loancategory = ["Home Loan", "Land Loan"];
    const propertytype = ["Residential", "Rented"];
    const category = ["Home Loan", "Land Loan"];
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      iddata,
      appData,
      modulename,
      modulenamesub,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      propertytype,
      key,
      familymembers,
      state,
      tempcatch,
      today,
      loancategory,
      insurancecom,
      nomineetetals,
      category,
      yearlist,
    };
  },
  data(){
    return {
        xdata:[],
    };
  },
  mounted() {},
  methods: {
    updatedetails(name, val) {
      if (name == "holdernameid") {
        this.appData.holdernameid = val.id;
        this.appData.holdername = val.name;
      }
    },
    managedata(name, val) {
      if (this.appData[name] != val && val != "") {
        ;
        this.appData[name] = val;
        if (name == "status") {
          this.appData.financeyear = "";
          this.appData.paidyear = "";
          this.appData.marketvalue = "";
          this.appData.tenantname = "";
          this.appData.advancereceived = "";
          this.appData.startdate = "";
          this.appData.enddate = "";
          this.appData.rentamount = "";
          this.appData.propertytax = "";
          this.appData.advancereceived = "";
        }
        if (name == "propertytype") {
          if (val == "Rented") {
            this.appData.ownedby = "";
            this.appData.ownership = "";
            this.appData.occupanttype = "";
            this.appData.registrationnumber = "";
            this.appData.propertytax = "";
            this.appData.paidyear = "";
            this.appData.financeyear = "";
            this.appData.marketvalue = "";
          } else {
            this.appData.advancepaid = 0;
            this.appData.rent = 0;
            this.appData.startdate = "";
            this.appData.enddate = "";
          }
        }
      }
    },
    managefloat(name, val) {
      this.$set(this.appData, name, parseFloat(val));
    },
    updatedetailsnom(name, val) {
      if (name == "nomineenameid") {
        this.appData.nomineenameid = val.id;
        this.appData.nomineename = val.name;
      }
      else if (name == "financeyear") {
        this.appData.financeyearfrom = val.to;
        this.appData.financeyearto = val.from;
        this.appData.financeyear = val.id;
      }
    },
    updateValue(value) {
      if (value.target.files[0].size <= 2 * 1048576) {
        this.appData.fileData.file = value.target.files[0];
        this.appData.fileData.name = value.target.files[0].name;
        this.appData.fileData.size = value.target.files[0].size;
        this.appData.fileData.type = value.target.files[0].type;
      } else {
        this.$swal({
          icon: "error",
          title: "File limit Reached!",
          text: "File size must be maximum 2 mb .",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    },
    emicalculate() {
      var emi = 1;
      var p = this.appData.sanctionedamount;
      var r = this.appData.rateofintrest;
      var n = this.appData.tenure * 12;
      var r1 = (r / 12) / 100;
      var r2 = 1 + r1;
      var temp = Math.pow(1 + r1, n) / (Math.pow(1 + r1, n) - 1);
      emi = Math.ceil(p * r1 * temp);
      this.appData.emi = emi;
      if(this.appData.startdate){
        var tempx=this.appData.startdate
        tempx=tempx.split('-')
        var startdate = new Date(tempx[2], tempx[1], tempx[0])
        var enddate = new Date()
        let months;
        months = (enddate.getFullYear() - startdate.getFullYear()) * 12;
        months -= startdate.getMonth();
        months += enddate.getMonth();
        var pa=p
        var rx=0
        var px=0
        for(var i=0;i<months;i++){
            rx=(emi*r1)/100
            px=emi-rx
            pa=pa-px
            this.appData.prinipalamount=Math.round(pa*100)/100
            this.xdata.push(px,rx,pa)
        }
      }
    },



  },
};
</script>



<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
